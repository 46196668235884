<template>
  <layout-user-dashboard>
    <template #default>
      <h1 class="margin-s-bottom">{{ tt("userDashboard.reviews.pageTitle") }}</h1>
      <p class="text-l margin-max-bottom">
        {{ tu("userDashboard.reviews.introText") }}
      </p>

      <custom-crud-list v-bind="listConfiguration">
        <template #cell-title="{item}">
          <span
            v-tooltip="{content:item.title, translate: false}"
            class="text-overflow-ellipsis inline-block max-width-150 cursor-pointer" >
            {{item.title}}
          </span>
        </template>
        <template #cell-text="{item}">
          <span
            v-tooltip="{content:item.text, translate: false}"
            class="text-overflow-ellipsis inline-block max-width-150 cursor-pointer" >
            {{item.text}}
          </span>
        </template>
        <template #cell-productRating="{item}">
          <form-input type="starRating"
                      v-model="item.productRating"
                      :iconSize="1.75"
                      disabled="true"
                      :auto-input-styling="false"
                      theme-style="none"
          >

          </form-input>
        </template>
        <template #cell-vendorRating="{item}">
          <form-input type="starRating"
                      v-model="item.vendorRating"
                      :iconSize="1.75"
                      disabled="true"
                      :auto-input-styling="false"
                      theme-style="none"
          >

          </form-input>
        </template>
      </custom-crud-list>
    </template>
  </layout-user-dashboard>
</template>

<script>
import { computed } from "vue";
import CustomCrudList from "@/client/components/crud/customV1/List.vue";

export default {
  layout: {
    component: "default",
    params: {
      title: "userDashboard.reviews.pageTitle",
      subTitle: "userDashboard.reviews.subTitle",
      containerClass: "margin-top-top margin-bottom-top padding-remove"
    },
  },
  components: {
    CustomCrudList
  },
  middleware: {
    requireUser: {},
  },
  data: function () {
    return {
      listConfiguration: {
        subject: "review",
        listSrc: "entity/review",
        showIdField: true, // bool deafult true
        sortable: false, //bool, default false
        publishable: false, //bool, default false
        enableMassActions: false,
        editRoutePrefix: "admin-", //**
        createRoutePrefix: "admin-",//**
        deleteRoutePrefix: "ecommerce/",//**
        additionalListData: {
          simplified: false,
          translate: false,
        },
        constantFilters: {
          OwnerId: computed(() => this.$store.getters['user/profile']?.id)
        },
        fields: {
          // these are the table columns
          title: {
            itemKey: "title",
            label: "ecommerce.reviews.fields.title",
          },
          text: {
            itemKey: "text",
            label: "ecommerce.reviews.fields.text",
          },
          productRating: {
            itemKey: "productRating",
            label: "ecommerce.reviews.fields.productRating",
          },
          vendorRating: {
            itemKey: "vendorRating",
            label: "ecommerce.reviews.fields.vendorRating",
          },

        },
        actions: false,
      },
    };
  },
  meta: "userDashboard.reviews.browserTitle",
};
</script>

<style scoped lang="scss"></style>
